import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, Container, Dropdown, Form, InputGroup, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, MenuItem, Popover, TableContainer } from '@mui/material';
import { Apiendpoint } from '../utils/apiList';
import Iconify from '../components/iconify/Iconify';
import { fCurrency } from '../utils/formatNumber';
import Label from '../components/label/Label';

const warehouseData = [
  {
    warehouse_id: '1956474000000343009',
    warehouse_name: 'Warehouse Jakarta - Good Bags',
  },
  {
    warehouse_id: '1956474000000345001',
    warehouse_name: 'Warehouse Jakarta - Broken Bags',
  },
  {
    warehouse_id: '1956474000016090003',
    warehouse_name: 'Non Goods',
  },
  {
    warehouse_id: '1956474000053355009',
    warehouse_name: 'PETSKITA Warehouse',
  },
  {
    warehouse_id: '1956474000058509057',
    warehouse_name: 'Toko Cabang',
  },
  {
    warehouse_id: '1956474000058663121',
    warehouse_name: 'Titip Aja',
  },
  {
    warehouse_id: '1956474000069612759',
    warehouse_name: 'Warehouse Surabaya - Good Bags',
  },
  {
    warehouse_id: '1956474000069626867',
    warehouse_name: 'Warehouse Surabaya - Broken Bags',
  },
  {
    warehouse_id: '1956474000069990937',
    warehouse_name: 'Crewdible Warehouse',
  },
  {
    warehouse_id: '1956474000077025853',
    warehouse_name: 'Crewdible Pro Sofa Pet',
  },
  {
    warehouse_id: '1956474000102005025',
    warehouse_name: 'TOKOCABANG SURABAYA',
  },
  {
    warehouse_id: '1956474000145162569',
    warehouse_name: 'WH B2C (Teguh)',
  },
];

export default function CreateSalesorderPage() {
  const [filterCustomer, setFilterCustomer] = useState('');
  const [open, setOpen] = useState(null);
  const [filterItems, setFilterItems] = useState('');
  const [customer, setCustomer] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [salesorder, setSalesorder] = useState({
    company_name: '',
    contact_id: '',
    salesorder_number: '',
    reference_number: '',
    salesorder_date: '',
    expected_shipment_date: '',
  });
  const [salesorderItems, setSalesOrderItems] = useState({
    item_id: '',
    item_name: '',
    sku: '',
    exp_date: '',
    rate: 0,
    quantity: 1,
    discount: 0,
    warehouse_id: '1956474000000343009',
    warehouse_name: 'Warehouse Jakarta - Good Bags',
    tax_name: '',
    tax_id: '',
  });
  const globalState = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      searchCustomer();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [filterCustomer]);
  const searchCustomer = () => {
    axios
      .get(`${Apiendpoint.filter_customer}?filtered=${filterCustomer}`, {
        headers: { Authorization: `Bearer ${globalState.auth_zoho}` },
      })
      .then((res) => {
        const response = JSON.parse(res.data.message[0].body);
        console.log(response);
        setCustomer(response.contacts);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
        }
        console.log(err);
      });
  };

  useEffect(() => {
    getTaxList();
  }, []);

  const getTaxList = () => {
    axios
      .get(`${Apiendpoint.tax}`, { headers: { Authorization: `Bearer ${globalState.auth_zoho}` } })
      .then((res) => {
        const response = JSON.parse(res.data.message[0].body);
        console.log('tax list :', response);
        setTaxList(response.taxes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChooseCustomer = (name, id) => {
    setSalesorder({
      ...salesorder,
      company_name: name,
      contact_id: id,
    });
  };
  const handleCancel = () => {
    navigate('/dashboard/salesorder');
  };
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    dispatch({type : 'SET_ITEM_MARK_REMOVE', value: id})
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      axios
        .get(`${Apiendpoint.filter_items}?filtered=${filterItems}`, {
          headers: { Authorization: `Bearer ${globalState.auth_zoho}` },
        })
        .then((res) => {
          const response = JSON.parse(res.data.message[0].body);
          console.log(response);
          setItemList(response.items);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [filterItems]);
  console.log(salesorderItems);
  const handleCartSalesorderItems = () => {
    const data = {
      item_id: salesorderItems.item_id,
      item_name: salesorderItems.item_name,
      sku: salesorderItems.sku,
      exp_date: salesorderItems.exp_date,
      rate: salesorderItems.rate,
      quantity: salesorderItems.quantity,
      discount: salesorderItems.discount,
      warehouse_id: salesorderItems.warehouse_id,
      warehouse_name: salesorderItems.warehouse_name,
      tax_name: salesorderItems.tax_name,
      tax_id: salesorderItems.tax_id,
      amount: salesorderItems.rate * salesorderItems.quantity,
    };
    dispatch({ type: 'SET_SALESORDER_ITEMS', value: data });
    setSalesOrderItems({
      item_id: '',
      item_name: '',
      sku: '',
      exp_date: '',
      rate: 0,
      quantity: 1,
      discount: 0,
      warehouse_id: '1956474000000343009',
      warehouse_name: 'Warehouse Jakarta - Good Bags',
      tax_name: '',
      tax_id: '',
    });
    setFilterItems('');
  };
  const handleRemoveLineItems = () => {
    dispatch({type : 'SET_REMOVE_SALESORDER_ITEMS', value: globalState.item_mark_remove});
    setOpen(null);
}
const handleEditLineItems = () => {
    dispatch({type: 'SET_EDIT_SALESORDER_ITEMS', value : globalState.item_mark_remove});
    const row = globalState.line_item_edit;
    console.log(row)
    setSalesOrderItems({
        item_id: row[0].item_id,
        item_name: row[0].item_name,
        sku: row[0].sku,
        exp_date: row[0].exp_date,
        rate: row[0].rate,
        quantity: row[0].quantity,
        discount: row[0].discount,
        warehouse_id: row[0].warehouse_id,
        warehouse_name: row[0].warehouse_name,
        tax_name: row[0].tax_name,
        tax_id: row[0].tax_id,
    });
    dispatch({type : 'SET_REMOVE_SALESORDER_ITEMS', value: globalState.item_mark_remove});
    setOpen(null);

  }
  return (
    <>
      <Container>
        <p>Create New Sales Order</p>
        <Dropdown style={{ maxWidth: 500, minWidth: 450 }}>
          <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ maxWidth: 500, minWidth: 450 }}>
            {salesorder.company_name ? salesorder.company_name : 'choose customer'}
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ maxWidth: 500, minWidth: 450 }}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="customer name"
                aria-label="customer name"
                value={filterCustomer}
                onChange={(e) => setFilterCustomer(e.target.value)}
              />
            </InputGroup>
            {customer &&
              customer.map((item, index) => {
                const { company_name, contact_id } = item;
                return (
                  <Dropdown.Item key={index} onClick={() => handleChooseCustomer(company_name, contact_id)}>
                    {company_name}
                  </Dropdown.Item>
                );
              })}
          </Dropdown.Menu>
        </Dropdown>
        <Form.Label style={{ marginTop: 20 }} htmlFor="input1">
          Sales Order#
        </Form.Label>
        <Form.Control
        value={salesorder.salesorder_number}
        onChange={(e) => setSalesorder({...salesorder, salesorder_number: e.target.value})}
          type="text"
          id="input1"
          aria-describedby="passwordHelpBlock"
          style={{
            maxWidth: 500,
          }}
        />
        <Form.Label style={{ marginTop: 20 }} htmlFor="input1">
          Reference#
        </Form.Label>
        <Form.Control
        value={salesorder.reference_number}
        onChange={(e) => setSalesorder({...salesorder, reference_number: e.target.value})}
          type="text"
          id="input1"
          aria-describedby="passwordHelpBlock"
          style={{
            maxWidth: 500,
          }}
        />
        <Form.Label style={{ marginTop: 20 }} htmlFor="input1">
          Sales Order Date
        </Form.Label>
        <Form.Control
        value={salesorder.salesorder_date}
        onChange={(e) => setSalesorder({...salesorder, salesorder_date: e.target.value})}
          type="date"
          id="input1"
          aria-describedby="passwordHelpBlock"
          style={{
            maxWidth: 500,
          }}
        />
        <Form.Label style={{ marginTop: 20 }} htmlFor="input1">
          Expected Shipment Date
        </Form.Label>
        <Form.Control
        value={salesorder.expected_shipment_date}
        onChange={(e) => setSalesorder({...salesorder, expected_shipment_date: e.target.value})}
          type="date"
          id="input1"
          aria-describedby="passwordHelpBlock"
          style={{
            maxWidth: 500,
          }}
        />
        <div style={{ marginTop: 30 }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{''}</th>
                <th>Item Details</th>
                <th>Exp Date</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Discount</th>
                <th>Tax</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {globalState.salesorder_items &&
                globalState.salesorder_items.map((item, index) => {
                  const {
                    item_id,
                    item_name,
                    sku,
                    exp_date,
                    rate,
                    quantity,
                    discount,
                    warehouse_id,
                    warehouse_name,
                    tax_name,
                    tax_id,
                    amount,
                  } = item;
                  return (
                    <tr>
                      <td>
                        <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, item_id)}>
                          <Iconify icon={'eva:more-vertical-fill'} />
                        </IconButton>
                      </td>
                      <td>
                        <p>{item_name}</p>
                        <p>SKU = {sku}</p>
                      </td>
                      <td>{exp_date}</td>
                      <td>
                        <p>quantity : {quantity}</p>
                        <p>{warehouse_name}</p>
                      </td>
                      <td>
                        <p>{fCurrency(rate)}</p>
                      </td>
                      <td>
                        <p>{discount} %</p>
                      </td>
                      <td>
                        <p>
                          <Label>{tax_name}</Label>
                        </p>
                      </td>
                      <td>Rp {fCurrency(amount)}</td>
                    </tr>
                  );
                })}
              <tr>
                <td>
                  <IconButton size="large" color="inherit" onClick={handleCartSalesorderItems}>
                    <Iconify icon={'octicon:upload-24'} />
                  </IconButton>
                </td>
                <td>
                  {salesorderItems.item_name !== '' ? (
                    <div>
                      <p>{salesorderItems.item_name}</p>
                      <p>SKU : {salesorderItems.sku}</p>
                    </div>
                  ) : (
                    <Dropdown>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Items Filter"
                          aria-label="Items Filter"
                          value={filterItems}
                          onChange={(e) => setFilterItems(e.target.value)}
                        />
                      </InputGroup>
                      <Dropdown.Menu show={!!filterItems}>
                        {itemList &&
                          itemList.map((item, index) => {
                            const { item_name, sku, item_id, description, rate } = item;
                            return (
                              <Dropdown.Item
                                key={index}
                                onClick={() =>
                                  setSalesOrderItems({ ...salesorderItems, item_id, item_name, sku, rate })
                                }
                              >
                                {item_name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </td>
                <td>
                  <Form.Control
                    value={salesorderItems.exp_date}
                    onChange={(e) => setSalesOrderItems({ ...salesorderItems, exp_date: e.target.value })}
                    type="date"
                    id="inputExp"
                    aria-describedby="inputExp"
                  />
                </td>
                <td>
                  <Form.Control
                    value={salesorderItems.quantity}
                    onChange={(e) => setSalesOrderItems({ ...salesorderItems, quantity: e.target.value })}
                    type="number"
                    id="inputQty"
                    aria-describedby="inputQty"
                  />
                  <div style={{ display: 'flex', cursor: 'pointer', color: 'blue', marginTop: 10 }}>
                    <p className="fs-6">{salesorderItems.warehouse_name}</p>
                    <div>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Item ini di ambil dari {salesorderItems.warehouse_name}
                          </Tooltip>
                        }
                      >
                        {({ ref, ...triggerHandler }) => (
                          <Iconify {...triggerHandler} ref={ref} icon={'octicon:question-24'} />
                        )}
                      </OverlayTrigger>
                    </div>
                  </div>
                </td>
                <td>{fCurrency(salesorderItems.rate)}</td>
                <td>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      value={salesorderItems.discount}
                      onChange={(e) => setSalesOrderItems({ ...salesorderItems, discount: e.target.value })}
                      placeholder="0"
                      aria-label="Discount"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                  </InputGroup>
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {salesorderItems.tax_name ? salesorderItems.tax_name : 'tax'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {taxList &&
                        taxList.map((item, index) => {
                          const { tax_name, tax_id } = item;
                          return (
                            <Dropdown.Item
                              key={index}
                              onClick={() => setSalesOrderItems({ ...salesorderItems, tax_id, tax_name })}
                            >
                              {tax_name}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
                <td>{fCurrency(salesorderItems.rate * salesorderItems.quantity)}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div>
          <Button onClick={handleCancel} variant="danger" style={{ marginRight: 20 }}>
            Cancel
          </Button>
          <Button variant="success">Create Order</Button>
        </div>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditLineItems} >
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={handleRemoveLineItems} >
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          delete
        </MenuItem>
      </Popover>
    </>
  );
}
