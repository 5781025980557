import axios from "axios";
import React, {useEffect, useState} from "react";
import { Badge, Button, Col, Container, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { TableContainer } from "@mui/material";
import { Apiendpoint } from "../utils/apiList";
import Label from "../components/label/Label";
import Scrollbar from "../components/scrollbar/Scrollbar";
import { fCurrency } from "../utils/formatNumber";


export default function SalesorderDetailsPage () {
    const globalState = useSelector(state => state);
    const navigate = useNavigate();
    const [salesorder, setSalesorder] = useState({
        salesorder_number : '',
        order_status : '',
        invoiced_status : '',
        paid_status : '',
        shipped_status : '',
        reference_number : '',
        date : '',
        payment_terms_label : '',
        delivery_method : '',
        salesperson_name : '',
        custom_field: [],
    });
    const [billingAddress, setBillingAddress] = useState({
        address: "",
        attention: "",
        city: "",
        country: "",
        country_code: "",
        fax: "",
        phone: "",
        state: "",
        state_code: "",
        street2: "",
        zip: "",
    });
    const [shippingAddress, setShippingAddress] = useState({
        address: "",
        attention: "",
        city: "",
        country: "",
        country_code: "",
        fax: "",
        phone: "",
        state: "",
        state_code: "",
        street2: "",
        zip: "",
    });
    const [items, setItems] = useState([]);
    const [totalSalesorder, setTotalSalesorder] = useState({
        sub_total: '',
        total : '',
        adjustment: '',
        total_quantity : '',
    });
    const [tax, setTax] = useState([]);
    const [document, setDocument] = useState([]);
    useEffect(() => {
        getSalesorderDetails()
;    },[]);

    const getSalesorderDetails = () => {
        
        axios.get(`${Apiendpoint.salesorder_details}/${globalState.salesorder_id}`, {headers : {Authorization: `Bearer ${globalState.auth_zoho}`}})
        .then(res => {
            const response = JSON.parse(res.data.message[0].body)
            console.log(response);
            setSalesorder({
                salesorder_number : response.salesorder.salesorder_number,
                order_status : response.salesorder.order_status,
                invoiced_status: response.salesorder.invoiced_status,
                paid_status: response.salesorder.paid_status,
                shipped_status: response.salesorder.shipped_status,
                reference_number: response.salesorder.reference_number,
                date : response.salesorder.date,
                payment_terms_label: response.salesorder.payment_terms_label,
                delivery_method: response.salesorder.delivery_method,
                salesperson_name: response.salesorder.salesperson_name,
                custom_field: response.salesorder.custom_fields
            });
            setBillingAddress({
                address: response.salesorder.billing_address.address,
                attention: response.salesorder.billing_address.attention,
                city: response.salesorder.billing_address.city,
                country: response.salesorder.billing_address.country,
                country_code: response.salesorder.billing_address.country_code,
                fax: response.salesorder.billing_address.fax,
                phone: response.salesorder.billing_address.phone,
                state: response.salesorder.billing_address.state,
                state_code: response.salesorder.billing_address.state_code,
                street2: response.salesorder.billing_address.street2,
                zip: response.salesorder.billing_address.zip,
            });
            setShippingAddress({
                address: response.salesorder.shipping_address.address,
                attention: response.salesorder.shipping_address.attention,
                city: response.salesorder.shipping_address.city,
                country: response.salesorder.shipping_address.country,
                country_code: response.salesorder.shipping_address.country_code,
                fax: response.salesorder.shipping_address.fax,
                phone: response.salesorder.shipping_address.phone,
                state: response.salesorder.shipping_address.state,
                state_code: response.salesorder.shipping_address.state_code,
                street2: response.salesorder.shipping_address.street2,
                zip: response.salesorder.shipping_address.zip,
            });
            setItems(response.salesorder.line_items);
            setTotalSalesorder({
                sub_total: response.salesorder.sub_total,
                total: response.salesorder.total,
                adjustment: response.salesorder.adjustment,
                total_quantity: response.salesorder.total_quantity
            });
            setTax(response.salesorder.taxes);
            setDocument(response.salesorder.documents);
        })
        .catch(err => {
            if(err.response.status === 401){
                navigate('/');
            }
            console.log(err);
        })
    }
    const handleClose = () =>{
        navigate('/dashboard/salesorder')
      }
    return(
        <>
        <Container>
            <p className="fs-4" >
                sales order details
            </p>
            <p className="fs-5"> salesorder <Label>{salesorder.salesorder_number === undefined ? "" : salesorder.salesorder_number}</Label> </p>
            <Row style={{marginBottom: 20}}>
                <Col>
                <div>
                    <p>status</p>
                </div>
            <div style={{borderLeft: "4px solid orange", padding:10}} >
                <div style={{display: "flex", padding: 5}} >
                    <div>
                        <p>order status</p>
                    </div>
                    <div>
                        : <Label color={salesorder.order_status === 'draft' && 'warning' || 'primary'}>{salesorder.order_status === undefined ? "" : salesorder.order_status}</Label>
                    </div>
                </div>
                <div style={{display: "flex", padding: 5}} >
                    <div>
                        <p>Invoice</p>
                    </div>
                    <div>
                        : <Label color={salesorder.invoiced_status === 'not_invoiced' && 'error'|| 'primary' }>{salesorder.invoiced_status === undefined ? "" : salesorder.invoiced_status}</Label>
                    </div>
                </div>
                <div style={{display: "flex", padding: 5}} >
                    <div>
                        <p>Payment</p>
                    </div>
                    <div>
                        : <Label color={salesorder.paid_status === 'unpaid' && 'error' || 'success'}>{salesorder.paid_status === undefined ? "" : salesorder.paid_status}</Label>
                    </div>
                </div>
                <div style={{display: "flex", padding: 5}} >
                    <div>
                        <p>Shipment</p>
                    </div>
                    <div>
                        : <Label color={salesorder.shipped_status === 'pending' && 'warning' || 'success'} >{salesorder.shipped_status === undefined ? "" : salesorder.shipped_status}</Label>
                    </div>
                </div>
            </div>
            <Row >
                <Col>
                Reference No
                </Col>
                <Col>
                : {salesorder.reference_number === undefined ? "" : salesorder.reference_number}
                </Col>
            </Row>
            <Row>
                <Col>
                Order Date
                </Col>
                <Col>
                : {salesorder.date === undefined ? "" : salesorder.date}
                </Col>
            </Row>
            <Row>
                <Col>
                Payment Terms
                </Col>
                <Col>
                : {salesorder.payment_terms_label === undefined ? "" : salesorder.payment_terms_label}
                </Col>
            </Row>
            <Row>
                <Col>
                Delivery Methode
                </Col>
                <Col>
                : {salesorder.delivery_method === undefined ? "" : salesorder.delivery_method}
                </Col>
            </Row>
            <Row>
                <Col>
                Sales Person
                </Col>
                <Col>
                : {salesorder.salesperson_name === undefined ? "" : salesorder.salesperson_name}
                </Col>
            </Row>
            {salesorder.custom_field && salesorder.custom_field.map((item, index) => {
                    const {label, value} = item
                    return(
            <Row>
                <Col>
                {label}
                </Col>
                <Col>
                : {value}
                </Col>
            </Row>
                    )
                })}
                </Col>
                <Col style={{borderLeft: "4px solid gray"}}>
                <div style={{borderBottom: "4px solid gray"}}>
                    <p>Billing Address</p>
                    <p>
                        {billingAddress.attention}, <br/>
                        {billingAddress.address}, <br/>
                        {billingAddress.street2}<br/>
                        {billingAddress.city}, {billingAddress.state}<br/>
                        {billingAddress.state_code} {billingAddress.country}
                    </p>
                   
                </div>
                <div style={{borderBottom: "4px solid gray"}}>
                    <p>Shipping Address</p>
                    <p>
                        {shippingAddress.attention}, <br/>
                        {shippingAddress.address}, <br/>
                        {shippingAddress.street2}<br/>
                        {shippingAddress.city} {shippingAddress.state}<br/>
                        {shippingAddress.state_code} {shippingAddress.country}

                    </p>
                </div>
                </Col>
            </Row>
            <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>Items & Description</th>
          <th>Ordered</th>
          <th>Warehouse Name</th>
          <th>Status</th>
          <th>Rate</th>
          <th>Discount</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {items && items.map((item, index) => {
            const {description, sku, quantity, unit, warehouse_name, quantity_invoiced, quantity_packed, rate, discount, item_total} = item
            return(
        <tr key={index} >
          <td>
            <p className="fs-6" >
                {description}
            </p>
            <p className="fs-6" >
               SKU = {sku}
            </p>
          </td>
          <td>
            <p className="fs-6" >
                {quantity}
            </p>
            <p className="fs-6" >
                {unit}
            </p>
          </td>
          <td>{warehouse_name}</td>
          <td>
            <p className="fs-6">
                {quantity_packed} Packed
            </p>
            <p className="fs-6">
                {quantity_invoiced} Invoiced
            </p>
          </td>
          <td>Rp {fCurrency(rate)}</td>
          <td>{discount}</td>
          <td>Rp {fCurrency(item_total)}</td>
        </tr>
            )})}
       
      </tbody>
    </Table>

            </TableContainer>
            </Scrollbar>
            <div >
                <div style={{display:"flex"}} >
                    <p className="fs-4">Sub Total</p>
                    <p className="fs-4">: Rp {totalSalesorder.sub_total === undefined ? "" : fCurrency(totalSalesorder.sub_total)}</p>
                </div>
                <div style={{display:"flex"}} >
                    <p className="fs-6">Total Quantity</p>
                    <p className="fs-6">: {totalSalesorder.total_quantity === undefined ? "" : totalSalesorder.total_quantity}</p>
                </div>
                {tax && tax.map((item, index) => {
                    const {tax_name, tax_amount} = item 
                    return(
                <div style={{display:"flex"}} key={index}>
                    <p className="fs-6">{tax_name}</p>
                    <p className="fs-6">: Rp {fCurrency(tax_amount)}</p>
                </div>
                    )
                })}
                <div style={{display:"flex"}} >
                    <p className="fs-6">Adjustment</p>
                    <p className="fs-6">: {totalSalesorder.adjustment === undefined ? "" : totalSalesorder.adjustment}</p>
                </div>
                <div style={{display:"flex", borderTop: "3px solid orange", borderBottom: "3px solid orange", alignItems:"center"}} >
                    <div style={{display:"flex"}} >
                    <p className="fs-4">Total</p>
                    <p className="fs-4">: Rp {totalSalesorder.total === undefined ? "" : fCurrency(totalSalesorder.total)}</p>
                    </div>
                    <div style={{marginLeft: 20}} >
                    <Button variant="primary"> Attachment {document.length}</Button>
                    </div>
                </div>
            </div>
            <div style={{marginTop:20, display:"flex"}} >
                <Button onClick={handleClose} variant="warning" style={{marginRight:10}} >Close</Button>
                <Button variant="success">Edit</Button>
            </div>
        </Container>
        </>
    )
}