
const developmentEndpoint = 'http://localhost:4027/api';
const production = 'https://api.happycat-happydog.id/api';
const currentEndpoint = production;

export const Apiendpoint = {
    zoho_auth : `${currentEndpoint}/auth`,
    current_user : `${currentEndpoint}/auth/me`,
    dunex_auth : `${currentEndpoint}/auth-dunex/devAuth`,
    // list component
    // tambahkan parameter untuk page
    salesorder : `${currentEndpoint}/salesorder`,
    items : `${currentEndpoint}/items`,
    customers : `${currentEndpoint}/customers`,
    invoices : `${currentEndpoint}/invoices`,
    shipments : `${currentEndpoint}/shipment/list`,
    packages : `${currentEndpoint}/packages`,
    tax : `${currentEndpoint}/tax`,
    // kirimkan query
    filter_salesorder :`${currentEndpoint}/salesorder/filter`,
    filter_customer : `${currentEndpoint}/customers/filter`,
    filter_items : `${currentEndpoint}/items/filter`,
    filter_shipment : `${currentEndpoint}/shipment/filter`,

    // details component
    // tambahkan id parameter
    shipmet_details : `${currentEndpoint}/shipment`,
    salesorder_details : `${currentEndpoint}/salesorder/details`,
    track_dunex_shipment : `${currentEndpoint}/shipment-dunex/track-shipment`,

    // push data
    post_dunex_data : `${currentEndpoint}/shipment-dunex/shipment-order`,

    // update data
    // tambahkan params id
    update_shipment : `${currentEndpoint}/shipment`

    
    
}