import React, { useEffect } from "react";
import { Badge, Image } from "react-bootstrap";
import { HPIlogo } from "../components/assets";



export default function SplashPage () {
    useEffect(() => {
        setTimeout(() => {
            window.location.replace("http://accounts.zoho.com/oauth/v2/auth?scope=ZohoInventory.FullAccess.all&client_id=1000.54DN3XTCTPX64K8TGGZVCDT80XO7EQ&state=testing&response_type=code&redirect_uri=https://inventory.happycat-happydog.id/signin&access_type=offline")
        }, 3000);
    },[])
    return(
        <div style={{height: "100vh", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}} >
            
            <Image src={HPIlogo} width={500} fluid />
            <p className="fs-6" >
                    Inventory By Happy Pet Indonesia
            </p>
            
        </div>
    )
}