import React from "react";
import { Button, Modal } from "react-bootstrap";
import Iconify from "../iconify/Iconify";



export default function ModalsComponent ({show, title,  desc, handleClose, type}) {
    function IconType ()  {
        if(type === 'success'){
            return <Iconify width={50} height={50} icon={'octicon:checklist-24'}/>
        }
        if(type === 'error'){
            return <Iconify width={50} height={50} icon={'gala:file-error'}/>
        }
    }
    return(
        <Modal  
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{marginTop: 200}}
        >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{display:"flex", justifyContent:"center"}} >
            <IconType/>
        </div>
            <p className="fs-5" >{desc}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
        )
}