const { createStore } = require("redux");

const initialState = {
    auth_zoho : null,
    shipment_id : null,
    profile_uri : null,
    current_account : null,
    current_email : null,
    salesorder_id: null,
    salesorder_items : [],
    item_mark_remove : null,
    line_item_edit : null,
}

const reducer = (state = initialState, action) => {
    if(action.type === 'SET_AUTH_ZOHO'){
        return {
            ...state,
            auth_zoho : action.value
        }
    }
    if(action.type === 'SET_ITEM_MARK_REMOVE'){
        return {
            ...state,
            item_mark_remove : action.value
        }
    }
    if(action.type === 'SET_SALESORDER_ID'){
        return {
            ...state,
            salesorder_id : action.value
        }
    }
    if(action.type === 'SET_SHIPMENT_ID'){
        return {
            ...state,
            shipment_id : action.value
        }
    }
    if(action.type === 'SET_PROFILE_URI'){
        return {
            ...state,
            profile_uri : action.value
        }
    }
    if(action.type === 'SET_CURRENT_ACCOUNT'){
        return {
            ...state,
            current_account : action.value
        }
    }
    if(action.type === 'SET_CURRENT_EMAIL'){
        return {
            ...state,
            current_email : action.value
        }
    }
    if(action.type === 'SET_REMOVE_SALESORDER_ITEMS'){
        return {
            ...state,
            salesorder_items : state.salesorder_items.filter(item => item.item_id !== action.value)
        }
    }
    if(action.type === 'SET_EDIT_SALESORDER_ITEMS'){
        return {
            ...state,
            line_item_edit : state.salesorder_items.filter(item => item.item_id === action.value)
        }
    }
    if(action.type === 'SET_SALESORDER_ITEMS'){
        if(state.salesorder_items.length === 0){
            return {
                ...state,
                salesorder_items: [action.value]
            }
        }
        return {
            ...state,
            salesorder_items : [...state.salesorder_items, action.value],
        }
    }
   
    return state
}
const store = createStore(reducer);
export default store;