import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import SplashPage from './pages/Splash';
import SalesorderPage from './pages/salesorderPage';
import ItemsPage from './pages/Items';
import CustomersPage from './pages/Customers';
import InvoicesPage from './pages/InvoicesPage';
import ShipmentPage from './pages/ShipmentOrder';
import PackagesPage from './pages/Packages';
import ShipmentDetails from './pages/ShipmentDetails';
import SalesorderDetailsPage from './pages/SalesorderDetails';
import CreateSalesorderPage from './pages/SalesorderCreate';

// ----------------------------------------------------------------------

export default function Router() {
  const code = useLocation();
  const paths = new URLSearchParams(code.search);
  const codes = paths.get("code");
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'salesorder', element: <SalesorderPage /> },
        { path: 'customers', element: <CustomersPage /> },
        { path: 'items', element: <ItemsPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'invoices', element: <InvoicesPage /> },
        { path: 'shipment', element: <ShipmentPage /> },
        { path: 'packages', element: <PackagesPage /> },
        { path: 'salesorderdetails', element: <SalesorderDetailsPage /> },
        { path: 'salesordercreate', element: <CreateSalesorderPage /> },
        { path: 'shipmentdetails', element: <ShipmentDetails /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'splash',
      element: <SplashPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="splash" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: '/signin/*', element: <LoginPage /> },
        { path: `:${codes}`, element: <LoginPage /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
