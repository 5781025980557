import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Apiendpoint } from '../utils/apiList';
import ModalsComponent from '../components/modals';
import Iconify from '../components/iconify/Iconify';

export default function ShipmentDetails() {
  const globalState = useSelector((state) => state);
  const navigate = useNavigate();
  useEffect(() => {
    getShipmentDetails();
  }, []);
  const [details, setDetails] = useState({
    customer_name: '',
    shipment_number: '',
    shipping_address: '',
    salesorder_number: '',
    shipment_date: '',
    quantity: '',
    custom_field: [],
  });
  const [manualDetails, setManualDetails] = useState({
    no_phone: '',
    transporter: '',
    shipping_note: ' ',
  });
  const [updateDetails, setUpdateDetails] = useState({
    shipment_number: '',
    date: '',
    reference_number: '',
    contact_persons: '',
    delivery_method: '',
    tracking_number: '',
    shipping_charge: '',
    exchange_rate: '',
    template_id: '',
    notes: '',
    salesorder_id: ''
  })
  const [items, setItems] = useState([]);
  const [shipping, setShipping] = useState('');
  const [billing, setBilling] = useState('');
  const [delivered, setDelivered] = useState(false);
  const [deliverHistory, setDeliverHistory] = useState([]);
  const [modals, setModals] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    title :'',
    type : '',
    desc: ''
  });
  const [packages, setPackages] = useState([]);

  const getDunexShipment = (e) => {
    axios.get(`${Apiendpoint.dunex_auth}`)
    .then(res => {
        const auth_token = res.data.data.token;
        axios.get(`${Apiendpoint.track_dunex_shipment}?shipId=${e}`, {headers : {Authorization : auth_token}})
        .then(res => {
            const response = JSON.parse(res.data.data)
            console.log(response, e)
            if(response.status === '000'){
                setDelivered(true);
                setDeliverHistory(response.data.order_data.history)
            }
            
        })
        .catch(err => {
            console.log(err)
        })

        console.log(auth_token);
    })
    .catch(err => {
        console.log(err)
    })
  }
  const getShipmentDetails = () => {
    axios
      .get(`${Apiendpoint.shipmet_details}/${globalState.shipment_id}`, {
        headers: { Authorization: `Bearer ${globalState.auth_zoho}` },
      })
      .then((res) => {
        const response = JSON.parse(res.data.message[0].body);
        const quantityPackage = response.shipmentorder.packages.map((item) => {
          return item.package_quantity;
        });
        
        getDunexShipment(response.shipmentorder.tracking_number);
        setDetails({
          customer_name: response.shipmentorder.customer_name,
          salesorder_number: response.shipmentorder.salesorder_number,
          shipment_number: response.shipmentorder.shipment_number,
          shipment_date: response.shipmentorder.date,
          quantity: quantityPackage,
          custom_field: response.shipmentorder.custom_fields,
        });
        setUpdateDetails({
            shipment_number: response.shipmentorder.shipment_number,
            date: response.shipmentorder.date,
            reference_number: response.shipmentorder.reference_number,
            contact_persons: response.shipmentorder.customer_id,
            shipping_charge: response.shipmentorder.shipping_charge,
            exchange_rate: response.shipmentorder.exchange_rate,
            template_id: response.shipmentorder.template_id,
            notes: response.shipmentorder.notes,
            salesorder_id: response.shipmentorder.salesorder_id,
            
        });
        setPackages(response.shipmentorder.packages)
        setShipping(
          `${response.shipmentorder.shipping_address.attention} ${response.shipmentorder.shipping_address.address} ${response.shipmentorder.shipping_address.city} ${response.shipmentorder.shipping_address.state} ${response.shipmentorder.shipping_address.phone}`
        );
        setBilling(
          `${response.shipmentorder.billing_address.attention} ${response.shipmentorder.billing_address.address} ${response.shipmentorder.billing_address.city} ${response.shipmentorder.billing_address.state} ${response.shipmentorder.billing_address.phone}`
        );
        const cf_filter = response.shipmentorder.custom_fields.filter(value => value.api_name === 'cf_carrier2');
        console.log(cf_filter[0].value);
        setManualDetails({
            ...manualDetails, 
            no_phone: response.shipmentorder.shipping_address.phone,
            transporter: cf_filter.length === 0 ? '' : cf_filter[0].value
        });
        axios
          .get(`${Apiendpoint.salesorder_details}/${response.shipmentorder.salesorder_id}`, {
            headers: { Authorization: `Bearer ${globalState.auth_zoho}` },
          })
          .then((res) => {
            const response = JSON.parse(res.data.message[0].body);
            console.log(response);
            setItems(response.salesorder.line_items);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              navigate('/');
            }
            console.log(err);
          });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
        }
        console.log(err);
      });
  };
  const handleClose = () => {
    navigate('/dashboard/shipment');
  };
  const handleDelivery = () => {
    const weight_filter = details.custom_field.filter((value) => value.api_name === 'cf_total_weights');
    const cf_total_weight = weight_filter.length === 0 ? '' : weight_filter[0].value;
    const data = {
      order_id: `HPI-${details.shipment_number}`,
      sales_order: details.salesorder_number,
      customer_name: details.customer_name,
      no_hp: manualDetails.no_phone,
      shipment_date: details.shipment_date,
      total_qty: details.quantity[0],
      total_weight: cf_total_weight,
      shipping_address: shipping,
      transporter: manualDetails.transporter,
      shipping_note: manualDetails.shipping_note,
      products: items.map((cart) => {
        const { item_custom_fields } = cart;
        const custom_filter = item_custom_fields.filter((e) => e.api_name === 'cf_exp_date');
        const exp_item = custom_filter.length === 0 ? '' : custom_filter[0].value;
        return {
          product_name: cart.description,
          sku: cart.sku,
          expired_date: exp_item,
          quantity: cart.quantity,
          warehouse_allocation: cart.warehouse_name,
          stock_unit: cart.unit,
        };
      }),
    };
    const dataUpdate = {
        shipment_number: updateDetails.shipment_number,
        date: updateDetails.date,
        reference_number: updateDetails.reference_number,
        // contact_persons: updateDetails.contact_persons,
        delivery_method: manualDetails.transporter,
        tracking_number: `HPI-${details.shipment_number}`,
        shipping_charge: updateDetails.shipping_charge,
        exchange_rate: updateDetails.exchange_rate,
        template_id: updateDetails.template_id,
        notes: updateDetails.notes
    }
    console.log("data update",data);
    
    axios.get(`${Apiendpoint.dunex_auth}`)
    .then(res => {
        const auth_token = res.data.data.token;
        axios.post(`${Apiendpoint.post_dunex_data}`, data, {headers : {Authorization: auth_token}})
        .then(res => {
            console.log(res);
            axios.put(`${Apiendpoint.update_shipment}/${globalState.shipment_id}?salesorderId=${updateDetails.salesorder_id}&packagesId=${packages[0].package_id}`, dataUpdate, {headers : {Authorization : `Bearer ${globalState.auth_zoho}`}})
            .then(res => {
                console.log(res);
                setModals(true);
                setModalDetails({
                    type: 'success',
                    title: 'shipment order berhasil !',
                    desc: 'shipment order berhasil dikirim ke dunex !'
                });
                getShipmentDetails();
            })
            .catch(err => {
                console.log(err);
                setModals(true);
                setModalDetails({
                    type: 'error',
                    title: 'half success !',
                    desc: `shipment order hanya berhasil terkirim ke dunex mohon update manual dengan cara edit shipment order dan isi manual dengan code "HPI-${details.shipment_number}" !`
                })
            })
        }).catch( err => {
            
            const detailsError = JSON.stringify(err.response.data.error);
            
            setModals(true);
            setModalDetails({
                type: 'error',
                title: 'shipment order failed !',
                desc: `berikut adalah kesalahan yang terjadi pada proses pengiriman data :
                ${detailsError}
                `
            });
            
            
        })

    })
    .catch(err => {
        console.log(err)
    })
  };
  const handleCloseModals = () => {
    setModals(false)
  }
  return (
    <>
      <Container>
     <ModalsComponent
      show={modals}
      type={modalDetails.type}
      title={modalDetails.title}
      desc={modalDetails.desc}
      handleClose={handleCloseModals}
      />
        <p className="fs-5">Shipment Details</p>
        <Row style={{ marginBottom: 20 }}>
          <Col>
            <Row style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
              <Col xs lg={4}>
                Customer Name
              </Col>
              <Col>: {details.customer_name === undefined ? '' : details.customer_name}</Col>
            </Row>
            <Row style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
              <Col xs lg={4}>
                Shipping Address
              </Col>
              <Col>: {shipping === undefined ? '' : shipping}</Col>
            </Row>
            <Row style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
              <Col xs lg={4}>
                Billing Address
              </Col>
              <Col>: {billing === undefined ? '' : billing}</Col>
            </Row>
            {!delivered && (
                <>
                <Row>
              <Col>
                <Form.Label htmlFor="input1">No Phone</Form.Label>
                <Form.Control
                  value={manualDetails.no_phone}
                  onChange={(e) => setManualDetails({ ...manualDetails, no_phone: e.target.value })}
                  placeholder="isi nomor telephone"
                  type="number"
                  id="input1"
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label htmlFor="input2">Transporter</Form.Label>
                <Form.Control
                  value={manualDetails.transporter}
                  onChange={(e) => setManualDetails({ ...manualDetails, transporter: e.target.value })}
                  placeholder="isi transporter"
                  type="text"
                  id="input2"
                  />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label htmlFor="input3">shipping note</Form.Label>
                <Form.Control
                  value={manualDetails.shipping_note}
                  onChange={(e) => setManualDetails({ ...manualDetails, shipping_note: e.target.value })}
                  placeholder="shipping note"
                  type="text"
                  id="input3"
                  />
              </Col>
            </Row>
                  </>           
                  )}
          </Col>
          <Col>
            <Row style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
              <Col xs lg={4}>
                shipment date
              </Col>
              <Col>: {details.shipment_date === undefined ? '' : details.shipment_date}</Col>
            </Row>
            <Row style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
              <Col xs lg={4}>
                sales order
              </Col>
              <Col>: {details.salesorder_number === undefined ? '' : details.salesorder_number}</Col>
            </Row>
            <Row style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
              <Col xs lg={4}>
                quantity
              </Col>
              <Col>: {details.quantity === undefined ? '' : details.quantity}</Col>
            </Row>
            {details.custom_field &&
              details.custom_field.map((item, index) => (
                <Row key={index} style={{ margin: 10, borderBottom: '2px solid black', marginBottom: 20 }}>
                  <Col xs lg={4}>
                    {item.label}
                  </Col>
                  <Col>: {item.value}</Col>
                </Row>
              ))}
          </Col>
        </Row>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Item Description</th>
              <th>SKU</th>
              <th>EXP Date</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item, index) => {
                const { item_custom_fields } = item;
                const custom_field_filter = item_custom_fields.filter((value) => value.api_name === 'cf_exp_date');
                const item_exp_date =
                  custom_field_filter.length === 0
                    ? ''
                    : custom_field_filter.map((e) => {
                        return e.value;
                      });
                return (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{item.description}</td>
                    <td>
                        {item.sku} <br/>
                        {item.warehouse_name}
                    
                    </td>
                    <td>{item_exp_date}</td>
                    <td>{item.quantity}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <div style={{ display: 'flex' }}>
          <Button variant="danger" style={{ marginRight: 10 }} onClick={handleClose}>
            Close
          </Button>
          {!delivered && 
          <Button variant="warning" style={{ marginRight: 10 }} onClick={handleDelivery}>
            Delivery
          </Button>
        }
          <Button variant="success">Edit</Button>
        </div>
        <Row>

        {deliverHistory && deliverHistory.map((item , index) => {
            
            return(
                <Col>
                <div style={{padding:20, border: "2px solid gray", borderRadius:20, marginTop:20, maxWidth:500  }} >
                    <Iconify width={30} height={30} icon={'fluent:vehicle-truck-cube-24-regular'}/>
                    <Row>
                        <Col>
                        do number
                        </Col>
                        <Col>
                        : {item.do_number}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        status
                        </Col>
                        <Col>
                        : {item.status_do}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        date
                        </Col>
                        <Col>
                        : {item.date}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        warehouse
                        </Col>
                        <Col>
                        : {item.warehose}
                        </Col>
                    </Row>
                </div>
                </Col>
               
               )})}
        </Row>
     
      </Container>
    </>
  );
}
